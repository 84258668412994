@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&family=Noto+Sans+JP:wght@400;500;700&display=swap");

* {
  margin: 0;
  padding: 0;
}

body {
  background-color: #080808;
}

::-moz-selection {
  color: #080808;
  background-color: #ffd337;
}

::selection {
  color: #080808;
  background-color: #ffd337;
}
